<template>
  <v-list-item dense @click="onClickBatchDelete">
    <v-list-item-title>
      {{deleteLabel| t}}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
import batch from '@/components/list/listToolbar/batch/batch.js'
export default {
  mixins: [batch],
  methods: {
    onClickBatchDelete() {
      this.$apopup.base({
        title: this.$t(`batch_delete.confirm`),
        bodySlot: () => import('@/components/list/listToolbar/batch/batchPopup.vue'),
        applyCallback: this.batchDelete,
        disabledApply: this.disabledApply,
        targetsText: this.targetsText,
        targets: this.targets,
        hasAnyDisabledTargets: this.hasAnyDisabledTargets,
      })
    },
    async batchDelete() {
      try {
        await this.batchDeleteApi(this.targets)
        this.$snotify.success(null, this.$t('batch_delete.successfully') )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('batch_delete.failure'),
        )
      } finally {
        this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
        this.listActions.index()
      }
    },
  },
  computed: {
    batchDeleteApi() {
      if(typeof this.config.batchRequest === 'function') {
        return this.config.batchRequest
      }
      return this.listApi.batchDeleteApi
    },
    deleteLabel() {
      return this.label || 'action.delete'
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>